import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.css';
import GlobalStyles from '../../styles/GlobalStyles';
import ThankYouPage from '../../components/thankYouPage/thankYouPage';
import Fevicon from '../../images/fevicon.png';
import '../../styles/style.css';

const ThankYouPageTemplate=()=>{
    React.useEffect(() => {
        if (!typeof window !== 'undefined') {
        window.scrollTo(0, 0);
        }
    }, []);
    const ThankYouPageSectionsData = useStaticQuery(
        graphql`
        query allThankYouPageQuery {
        allContentfulSolEcolution(
            filter: {node_locale: {eq: "en-US"}, solPageGroup: {title: {eq: "standAlone"}}}
        ) {
            edges {
            node {
                name
                slug
                title
                node_locale
                hasIndex
                sections {
                ... on ContentfulContent {
                    id
                    title
                    type
                    subText
                    header
                    contentDetails {
                    raw
                    }
                }
                }
            }
            }
        }
        }
        `);

    const title = ThankYouPageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.title || 'Home';
    const thankYouPageData = ThankYouPageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.sections[0];
    return <>
    <GlobalStyles/>
    <Helmet>
        <title>
            {title}
        </title>
        <link rel="icon" href={Fevicon} type="image/png" />
        {ThankYouPageSectionsData?.allContentfulSolEcolution?.edges[0]?.metadata && (
            <meta
            name="description"
            content={ThankYouPageSectionsData?.allContentfulSolEcolution?.edges[0]?.metadata}
            ></meta>
        )}
        {ThankYouPageSectionsData?.allContentfulSolEcolution?.edges[0]?.node?.hasIndex === false && <meta name="robots" content="noindex" />}
    </Helmet>
    
    <ThankYouPage sectionData={thankYouPageData}/>
    </>
};

export default ThankYouPageTemplate;

